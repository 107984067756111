@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Roboto;
  src: url('../assets/font/Roboto-VariableFont.woff2') format('woff2'),
  url('../assets/font/Roboto-VariableFont.woff') format('woff'),
  url('../assets/font/Roboto-VariableFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: JDZhengHT-EN;
  src: url('../assets/font/JDZhengHT-EN-Regular.woff2') format('woff2'),
  url('../assets/font/JDZhengHT-EN-Regular.woff') format('woff'),
  url('../assets/font/JDZhengHT-EN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-family: Roboto, "-apple-system", "BlinkMacSystemFont";
  --hover-state-color: #ff3f4f;
  --dp-hover-state-color: #990915;
  --btn-primary-bg: linear-gradient(90deg, #ff475d 0%, #ff0f23 100%);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: var(--font-family);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
#jdi-header, #jdi-footer {
  min-width: 1264px;
  margin: 0 auto;
}

.w-e-text-container [data-slate-editor] .table-container {
  border: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

body img, svg, video, canvas, audio, iframe, embed, object {
  display: inline;
}

:root {
  --sys-color-Primary100: #FFE7E9;
  --sys-color-Primary200: #FFCFD3;
  --sys-color-Primary300: #FF9FA7;
  --sys-color-Primary400: #FF6F7B;
  --sys-color-Primary500: #FF3F4F;
  --sys-color-Primary600: #FF0F23;
  --sys-color-Primary700: #CC0C1C;
  --sys-color-Primary800: #990915;
  --sys-color-Primary900: #66060E;
  --sys-color-Primary1000: #330307;
  --sys-color-Primarylinear: linear-gradient(90deg, #FF475D 0%, #FF0F23 100%);
  --sys-Text-Primary: #1A1A1A;
  --sys-Text-Secondary: #505259;
  --sys-Text-Tertiary: #888B94;
  --sys-Text-Disable: #C2C4CC;
  --sys-Text-Anti: #FFFFFF;

  --sys-background-MainGray: #F5F6FA;
  --sys-background-CardGray: #F7F8FC;
  --sys-background-White: #FFFFFF;
  --sys-background-Black: #000000;
  --sys-background-BgMask: #000000B2;
  --sys-background-BgMask2: #00000066;
  --sys-background-imgMask: #00000005;

  --sys-Surface-Outline300: #C2C4CC;
  --sys-Surface-Outline200: #E0E1E5;
  --sys-Surface-Outline100: #F0F1F2;
  --sys-Surface-Outline006: #0000000F;

  --sys-Surface-Surface300: #E7E7EB;
  --sys-Surface-Surface200: #F3F3F5;
  --sys-Surface-Surface100: #F7F8FC;
  --sys-Surface-Surface-White: #FFFFFF;

  --sys-State-Info: #289FEA;
  --sys-State-Info700: #207FBB;
  --sys-State-Info300: #A9D9F7;
  --sys-State-Info100: #EAF5FD;
  --sys-State-Success: #2FBC44;
  --sys-State-Succes700: #269636;
  --sys-State-Success300: #ACE4B4;
  --sys-State-Success100: #EAF8EC;
  --sys-State-Warning: #FBB731;
  --sys-State-Warning700: #C99227;
  --sys-State-Warning300: #FDE2AD;
  --sys-State-Warning100: #FFF8EA;
  --sys-State-Error: #FF0F23;
  --sys-State-Error700: #CC0C1C;
  --sys-State-Error300: #FF9FA7;
  --sys-State-Error100: #FFE7E9;
  --sys-State-Link: #1869F5;
}

a {
  text-decoration: none;
  color: #000;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  margin: 0;
  background: none;
  border: inherit;
  outline: none;
  cursor: pointer;
}
